import { Configurations } from './models'
import waitForElement from './services/waitForElement'

type MethodNames = 'init' | 'event'
export const DEFAULT_NAME = '_vi'

const VERSION = '3392'

/**
 * Represents a model that is created in embedded script
 * as part of script initialization.
 */
interface LoaderObject {
  /**
   * Queue that accumulates method calls during downloading
   * and loading of widget's script file.
   */
  q: Array<[MethodNames, Configurations]>
}

/**
 * Loads widget instance.
 *
 * @param win Global window object which stores pre-loaded and post-loaded state of widget instance.
 * @param defaultConfig A configurations that are merged with user.
 * @param scriptElement The script tag that includes installation script and triggered loader.
 * @param render A method to be called once initialization done and DOM element for hosting widget is ready.
 */
export default async (
  win: Window,
  defaultConfig: Configurations,
  scriptElement: Element | null,
  render: (element: HTMLElement, config: Configurations) => void
) => {
  //try to remember to bumb this number when pulling to main, no clear versioning system yet out.
  // TODO make this non-arbitrary and tech from package.json
  console.log('Initializing Voice Intuitive v'+VERSION)

  const widgetLoader = new WidgetLoader(win, defaultConfig, scriptElement, render)
  widgetLoader.validateState()
  await widgetLoader.addMethods()
}
class WidgetLoader {
  win: Window
  defaultConfig: Configurations
  scriptElement: Element | null
  render: (element: HTMLElement, config: Configurations) => void
  loaderObject: LoaderObject
  instanceName: string
  constructor (win: Window,
    defaultConfig: Configurations,
    scriptElement: Element | null,
    render: (element: HTMLElement, config: Configurations) => void) {
    this.win = win
    this.defaultConfig = defaultConfig
    this.scriptElement = scriptElement
    this.render = render

    // get a hold of script tag instance, which has an
    // attribute `id` with unique identifier of the widget instance
    this.instanceName =
        scriptElement?.attributes.getNamedItem('id')?.value ?? DEFAULT_NAME
    this.loaderObject = win[this.instanceName]
    console.log(this)
  }

  validateState () {
    if (!this.loaderObject || !this.loaderObject.q) {
      throw new Error(
          `Widget didn't find LoaderObject for instance [${this.instanceName}]. ` +
          'The loading script was either modified, no call to \'init\' method was done ' +
          `or there is conflicting object defined in \`window.${this.instanceName}\` .`
      )
    }
    // check that the widget is not loaded twice under the same name
    if (this.win[`loaded-${this.instanceName}`]) {
      throw new Error(
          `Widget with name [${this.instanceName}] was already loaded. ` +
          `This means you have multiple instances with same identifier (e.g. '${DEFAULT_NAME}')`
      )
    }
  }

  async addMethods () {
    const loaders = this.loaderObject.q
    const targetElement = await this.addInitMethod(loaders[0])
    for (const loader of loaders) {
      await this.addMethod(loader)
    }
    this.convertLoaders(targetElement)
  }

  async downloadCustomTranslations(path:string):Promise<Response|null>{

    const response = await fetch(path)
    if (!response.ok) {
      console.error(
        `An error has occured when fetching customTranslations configuration data: ${response.status} from another site: ${path}`)
      return null;
    }
    else {
      console.log(`loaded customTranslations configuration from another site: ${path}`)
      try{
        const configurations = await response.json()
        if(configurations.useCustomTranslationsFromAnotherSite){
          return this.downloadCustomTranslations(configurations.useCustomTranslationsFromAnotherSite)
        }
        else{
          return configurations.customTranslationsData
        }
      }
      catch(e){
        console.error(`error occurred while parsing customTranslations response from ${path}`)
        return null;
      }
    }
  }


  async addInitMethod ([methodName, conf]: [MethodNames, Configurations]) {
    if (methodName !== 'init') {
      throw new Error(
          `Failed to start Widget [${this.instanceName}]. 'init' must be called before other methods.`
      )
    }
    // eslint-disable-next-line no-case-declarations
    let loadedObject: Configurations

    // this clause is triggered if local config does not contain "skipRemoteConfig: true"
    if (!conf || !conf.skipRemoteConfig) {
      // fetch configuration file from CDN
      // if hostname starts with "www." remove it
      const hostname = window.location.hostname.startsWith('www.')
        ? window.location.hostname.replace('www.', '')
        : window.location.hostname

      const response = await fetch(
        `https://api-stage.voiceintuitive.com/config/${hostname}.json?version=${VERSION}`)

      if (!response.ok) {
        console.error(
          `An error has occured when fetching Voice Intuitive configuration data: ${response.status}. Loading default configuration.`)
      }
      else{

        const configurations = await response.json()
        
        if(configurations.useCustomTranslationsFromAnotherSite){
          configurations.customTranslationsData = 
          await this.downloadCustomTranslations(configurations.useCustomTranslationsFromAnotherSite) ||
          configurations.customTranslationsData ||
          {}
        }
        
        loadedObject = Object.assign(this.defaultConfig, configurations)
      }
    }
    

    loadedObject = Object.assign(this.defaultConfig, conf)
    
    if(conf && conf.skipRemoteConfig && conf.customerConfigURL){
      
      if(conf.debug){
        console.log("using (dynasty) customerConfigUrl:",conf.customerConfigURL)
      }
      
      const response = await fetch(
          conf.customerConfigURL
      )

      if (!response.ok) {
        console.error(
            `An error has occured when fetching *Dynasty* configuration data: ${response.status}. Loading default configuration.\n\n`)
      } else {
        const configurations = await response.json()
        loadedObject = Object.assign(
          this.defaultConfig, 
          configurations.dynastyConfigurations ?  
            configurations.dynastyConfigurations:
            configurations)

        //customTranslationsData
        loadedObject = Object.assign(
          loadedObject, 
          {customTranslationsData:
            configurations.customTranslationsData ?
            configurations.customTranslationsData:{}})
      }
    }

    if (loadedObject.debug) {
      console.log(`Starting widget [${this.instanceName}]`, loadedObject)
    }

    if (loadedObject.disabledPaths.includes(window.location.pathname)) {
      console.log('Voice Intuitive is disabled on this page.')
      return
    }

    await waitForElement(
      loadedObject.buttonContainerSelector,
      document.documentElement
    )

    // the actual rendering of the widget
    // eslint-disable-next-line no-case-declarations
    const wrappingElement = document.querySelector(
      loadedObject.buttonContainerSelector
    )

    if (!wrappingElement) {
      throw new Error(
          `Voice Intuitive widget failed to load. Element with selector ${loadedObject.buttonContainerSelector} not found. To fix this error, please add such an element somewhere in the DOM.`
      )
    }

    if (document.getElementById(`widget-${this.instanceName}`)) {
      console.warn(
          `Attempted to create an element inside shadow DOM for mounting the app, but element with id widget-${this.instanceName} already exists.`
      )
      return
    }

    // wrap element in shadow dom for obvious reasons
    // eslint-disable-next-line no-case-declarations
    const innerWrapper = this.win.document.createElement('div')
    loadedObject.prependButtonsInButtonContainer ? 
      wrappingElement.prepend(
        innerWrapper):
      wrappingElement.appendChild(
        innerWrapper)
    // eslint-disable-next-line no-case-declarations
    const shadow = innerWrapper.attachShadow({ mode: 'open' })
    const targetElement = shadow.appendChild(this.win.document.createElement('div'))
    
    try {
      await this.render(targetElement, loadedObject)
    } catch (e) {
      console.error('failed rendering widget', e)
      throw e
    }
    // store indication that widget instance was initialized
    this.win[`loaded-${this.instanceName}`] = true
    //sometimes dynamic pages lose the buttons when (re-)hydrating content, check kainuunsanomat.fi
    if(loadedObject.checkOnStartupIfButtonsAreMissing){
      let intervalCounter = 0;
      const intervalID = 
        setInterval(
          ()=>{
            if(this.win.document.contains(innerWrapper)){
              intervalCounter++;
              if(intervalCounter >= 10){
                clearInterval(intervalID);
                if(loadedObject.debug){
                  console.log("DEBUG: Clearing the interval for ButtonsMissing check",{intervalCounter})  
                }
              }
            }
            else{
              if(loadedObject.debug){
                console.log("DEBUG: page apparently cleared the buttonelement for the Voice Intuitive widget, service is configured to append it again.")
              }
              loadedObject.prependButtonsInButtonContainer ? 
                document.querySelector(
                  loadedObject.buttonContainerSelector
                )?.prepend(innerWrapper):
                document.querySelector(
                  loadedObject.buttonContainerSelector
                )?.append(innerWrapper)
              clearInterval(intervalID);
            }
          },
          500); 
    }
    return targetElement
  }

  private addMethod ([methodName, conf]: [MethodNames, Configurations]) {
    switch (methodName) {
      case 'init':
        console.warn('two init methods, ignoring', conf)
        break
      default:
        console.warn(`Unsupported method [${methodName}]`, conf)
    }
  }

  private convertLoaders (targetElement?: HTMLDivElement) {
    // once finished processing all async calls, we going
    // to convert LoaderObject into sync calls to methods
    this.win[this.instanceName] = (method: MethodNames | string, ...args: any[]) => {
      const firtstArg = args ? args[0] : undefined
      const otherArgs = args ? args.slice(1) : undefined
      console.log(method)
      switch (method) {
        case 'event': {
          targetElement?.dispatchEvent(
            new CustomEvent('widget-event', { detail: { name: firtstArg, args: otherArgs } })
          )
          break
        }
        default:
          // defaulting to using event here to simplify syntax a bit
          targetElement?.dispatchEvent(
            new CustomEvent('widget-event', { detail: { name: method, args } })
          )
          // console.warn(`Unsupported method [${method}]`, args)
      }
    }
  }
}

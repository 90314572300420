import { AppConfigurations } from '../context/ConfigContext'
import { domManipulator } from '../services/domManipulator'
import { doTranslate, LANGUAGE_KEY } from './translatorServices/translate'
import { PlayerController } from '../reader/readerServices/audio/PlayerController'
import { CustomTranslations } from './translatorServices/CustomTranslations'
import { clearLanguageUrlParameter, getLanguageUrlParameter } from '../utils/urlParameters'
import guessLanguage from '../services/guessLanguage'

interface TranslatorControllerProps {
  config: AppConfigurations
  originalLanguage: string
  playerController: PlayerController
  customTranslations: CustomTranslations
  isTranslated:boolean
  setIsTranslated:(visible: boolean) => void
  refreshTrigger: () => void
}
export class TranslatorController implements TranslatorControllerProps {
  config: AppConfigurations
  originalLanguage: string
  playerController: PlayerController
  customTranslations: CustomTranslations
  refreshTrigger: () => void
  isTranslated:boolean
  setIsTranslated:(visible:boolean) => void

  constructor (props: TranslatorControllerProps) {
    Object.assign(this, props)
    //console.log("translationController constructor called!")
    const translationTarget = getLanguageUrlParameter()
    
    if(translationTarget && translationTarget.toLowerCase()!==guessLanguage().toLowerCase()){
      if(
        this.config.translationLanguages && 
        !this.config.translationLanguages.includes(translationTarget.toLowerCase())){
          console.warn("WARNING: tried to translate to "+translationTarget+" but it wasn't in translationlanguages-list!")
      }
      if(
        this.config.translationLanguages && 
        this.config.translationLanguages.includes(translationTarget.toLowerCase())){
        setTimeout(()=>{
          this.translate(translationTarget.toLowerCase())}
        ,1000);
      }
    }
  }

  resetLanguage () {
    this.playerController.reset()

    domManipulator.restore()
    clearLanguageUrlParameter();
    localStorage.removeItem(LANGUAGE_KEY)

    // remove direction property if it exists which causes reading direction to revert to ltr
    // revert document language attribute to original
    document.body.style.removeProperty('direction')
    if (this.originalLanguage)document.documentElement.lang = this.originalLanguage
    this.refreshTrigger()
    this.setIsTranslated(false)
  }

  async translate (languageCode: string, entirePage: boolean = this.config.translateWholePage) {
    this.setIsTranslated(true)
    await doTranslate(
      this.config.debug,
      this.config.productionAPIUrl,
      this.config.useDevAPI,
      entirePage ? 'body' : this.config.translationAreaSelector ?? this.config.readAreaSelector,
      languageCode,
      this.originalLanguage,
      this.config.useLocalStorageForTranslationLanguage,
      this.playerController,
      this.customTranslations,
      this.config.devAPIUrl
    )

    clearLanguageUrlParameter();  
  }
}

import guessLanguage from '../../services/guessLanguage'
import supportedLanguages from './supportedLanguages'
import { fetchTranslation } from './fetchTranslation'
import { domManipulator } from '../../services/domManipulator'
import { clearHighlighting } from '../../reader/readerServices/highlight/highlightText'
import { PlayerController } from '../../reader/readerServices/audio/PlayerController'
import { CustomTranslations } from './CustomTranslations'
import { isLanguageSupported } from '../../reader/readerServices/ssml/getVoiceOptions'

export const LANGUAGE_KEY = 'voiceIntuitiveTranslationLanguage'

export async function doTranslate (
  debug: boolean,
  productionAPIUrl:string,
  useDevAPI: boolean,
  translationAreaSelector: string,
  languageCode: string,
  originalLanguage: string,
  useLocalStorage: boolean,
  playerController: PlayerController,
  customTranslations: CustomTranslations,
  devAPIUrl?:string
) {
  // eslint-disable-next-line no-constant-condition
  // do nothing if page is already in the selected language or the page is loading
  if (languageCode === guessLanguage(document) || playerController.status === 'loading') {
    return
  }

  // in theory, a specific area can be selected for translation
  const areaToTranslate = Array.from(document.querySelectorAll(
    translationAreaSelector)
    ) as HTMLElement[]
    if (areaToTranslate.length === 0) {
      console.error(
        'No content was found using translation area selector',
        translationAreaSelector
      )
    return
  }
  domManipulator.restore()
  resetPlayer(playerController)

  try {
    const translations = await fetchTranslation({
      content: areaToTranslate.flatMap((area:HTMLElement)=>(domManipulator.hijack(area))),
      languageCode,
      originalLanguage,
      productionAPIUrl,
      useDevAPI,
      devAPIUrl,
      debug,
      customTranslator: customTranslations.toCustomTranslator(languageCode)
    })

    if (debug) {
      console.log(translations)
    }

    // disable speaking if language is unsupported
    playerController.setStatus(isLanguageSupported(languageCode) ? 'uninitialized' : 'disabled')
    fixTextDirection(languageCode)
    domManipulator.setTranslations(translations)

    // if the use of localstorage is allowed, remember this language
    useLocalStorage &&
      localStorage.setItem('voiceIntuitiveTranslationLanguage', languageCode)

    document.documentElement.lang = languageCode
  } catch (e) {
    // process any errors
    console.error(e)
    playerController.setStatus('error')
  }
}
function fixTextDirection (languageCode: string) {
  // fetch information on this language (used to determine if this language is written from right to left)
  const languageData = supportedLanguages.find(
    (element) => element.code === languageCode
  )

  if (languageData == null) {
    throw new Error(
      'Could not determine what language is being read. Failed to determine reading direction.'
    )
  }
  if (languageData.rtl) {
    document.body.style.direction = 'rtl'
  } else {
    document.body.style.removeProperty('direction')
  }
}
function resetPlayer (playerController: PlayerController) {
  // set status to loading and reset audio player
  playerController.setStatus('loading')
  playerController.loadEmptyFile()
  clearHighlighting()
}

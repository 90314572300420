import { ComponentChildren, createContext, h } from 'preact'
import { Exclusions } from '../models'
import { useContext } from 'preact/hooks'
import { CustomTranslationsData } from '../translator/translatorServices/CustomTranslations'
import { PhoneticWords } from '../reader/readerServices/ssml/addPhonemes'

export type VoiceMode = 'clean' | 'soft'
export interface AppConfigurations {
  translateMenuParentSelector?: string
  debug: boolean
  productionAPIUrl:string
  useDevAPI: boolean
  devAPIUrl?:string
  skipRemoteConfig?: boolean
  readAreaSelector: string
  smallSize:boolean
  buttonContainerSelector: string
  prependButtonsInButtonContainer:boolean
  extraCSSForListenButton:string
  extraCSSForTranslateButton:string
  extraCSSForTranslateDropdown:string
  disabledPaths: string[]
  buttonMargin: string
  buttonForegroundColor: string
  buttonBackgroundColor: string
  controlPanelEnabled: boolean
  controlPanelShadowColor: string
  controlPanelForegroundColor: string
  controlPanelBackgroundColor: string
  seekBarColor?: string
  textHighlightingEnabled: boolean
  textHighlightDelay: number
  textHighlightPrimaryColor: string
  textHighlightSecondaryColor: string
  textHighlightFontColor?:string
  shareButtonEnabled: boolean
  useLocalStorageForSpeedSelector: boolean
  excludeText: Exclusions
  globalExcludeText: Exclusions
  translationEnabled: boolean
  translationButtonContainer?: string
  translationButtonMarginBottom: string
  useAlternativeTranslateButtonStyle:boolean
  translationAreaSelector: string
  translationLanguages?: string[]
  translationLanguageUrlParameterEnabled:boolean
  useLocalStorageForTranslationLanguage: boolean
  iframeContentSelector?: string
  speechCustomizationWordsToSpellOut:string[]
  hidden: boolean
  followHighlight?: boolean
  voiceMode?: VoiceMode
  showPlaylistItemIndicator?: boolean
  showGoogleAttributionTab?: boolean
  googleAttributionTabOffsetX?: number
  useCustomTranslationsFromAnotherSite?: string
  customTranslationsData?:CustomTranslationsData
  useFileAsEmptySoundbite?:boolean
  checkOnStartupIfButtonsAreMissing?:boolean
  autoTranslationEnabled: boolean
  translateWholePage:boolean
  customerConfigURL?:string
  useFlagSeeOriginal:boolean
  phonemes:PhoneticWords
}

const ConfigContext = createContext<AppConfigurations>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as AppConfigurations
)
interface Props {
  value: AppConfigurations
  children: ComponentChildren
}
export function ConfigContextProvider ({ value, children }: Props) {
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}
export function useAppConfig () {
  return useContext(ConfigContext)
}
